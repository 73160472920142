import React, { useState, useEffect } from 'react';
import anime from 'animejs';
import {
    useLocation
} from "react-router-dom";
import useScrollPosition from '@react-hook/window-scroll';

import './Home.scss';
import './Archive.scss';
import Archive from './Archive';

import arrow from './img/arrow_down.png';

function Home(props) {
    const scrollY = useScrollPosition(60 /*fps*/);
    const location = useLocation();

    useEffect(() => {
        const duration = 500;
        const easing = 'linear';

        var homeAnime = anime({
            targets: ['.home'],
            opacity: [0, 1],
            easing: easing,
            autoplay: true,
            duration: duration,
            complete: () => {
                if (location.pathname == "/info") {
                    if (props.size.width < props.mobileScreenWidth || document.body.scrollHeight - scrollY > 0) {
                        props.scrollToBottom();
                    }
                }
            }
        });

        homeAnime.play();
    }, []);

    return (
        <div className="homeContainer">
            <div className="home grid" style={{ opacity: 0 }}>

                <div className="arrowDown">
                    <img onClick={props.scrollToBottom} src={arrow}></img>
                </div>

                <div className=" main-text">
                    <p>Floral TV is a Zurich-based floral design studio founded by Jenni Tschugmell. The studio challenges conventionalism through forward thinking concepts and techniques while constantly seeking new challenges in different fields such as fashion, luxury, set design, press, events, restaurants/bars and spaces.</p>
                </div>

                <div className=" info-text" id="info">
                    <p>Please get in touch, for any inquiries and further information.</p>
                    <div className="contacts">
                        <span>
                            <span className="heading italic">Contact</span><br />
                            Jenni Tschugmell<br />
                            <a href="tel:+41 787556016">+41 787556016</a><br />
                            <a href="mailto:hello@jennifertschugmell.com">hello@jennifertschugmell.com</a><br />
                            IG  <a href="https://www.instagram.com/jenni_tschugmell/" target="_blank">@jennitschugmell</a>
                        </span>
                        <span>
                            <span className="heading italic">Selected Clients</span><br />
                            Jelmoli<br />
                            Mode Suisse<br />
                            Gessnerallee Zürich<br />
                            Estée Lauder<br />
                            Annabelle<br />
                            Issu Issu<br />
                            Acla<br />
                            Sula<br />
                        </span>
                    </div>
                </div>

            </div>

            {/* <Archive handleOnClick={props.handleOnClick} extraClass="noImages" /> */}
        </div>

    );
}

export default Home;