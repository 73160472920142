import React, { useState, useEffect, useRef } from 'react';
import {
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import anime from 'animejs';
import useWindowSize from './helpers/useWindowSize';
import useScrollPosition from '@react-hook/window-scroll';

import './Header.scss';
import logo from './img/logo_black.png';
import logoSmall from './img/logo_pink.png';

function Logo(props) {
  const history = useHistory();
  const scrollY = useScrollPosition(60 /*fps*/);
  const location = useLocation();
  const size = useWindowSize();

  const animationRef = useRef();
  const animationRefArrow = useRef();

  var styleLogo = { opacity: 0, transform: 'scale(' + props.scaleRatio + ')' };
  var styleImg = {};

  useEffect(() => {
    if (location.pathname != "/projects") {
      anime({
        targets: ".logo",
        opacity: 1,
        easing: 'easeInCubic',
        autoplay: true,
        duration: 500,
        delay: 100,
        complete: function () {
          // styleLogo = { opacity: 1 }
        }
      });
      if (size.width <= props.mobileScreenWidth) {
        if (scrollY < size.height * 0.37) {
          animationRef.current = anime({
            targets: ".logo",
            translateY: [props.logoMiddle, props.topMargin],
            scale: props.scaleRatio,
            easing: 'linear',
            autoplay: false,
          });
        }
      } else {
        animationRef.current = anime({
          targets: ".logo",
          translateY: [props.logoMiddle, props.topMargin],
          scale: [1, props.scaleRatio],
          easing: 'linear',
          autoplay: false,
        });
      }
    } else {
      document.getElementsByClassName('logo')[0].style.opacity = 1;
      document.getElementsByClassName('logo')[0].style.transform = 'scale(' + props.scaleRatio + ')';
    }

  }, [location.pathname, size.height, scrollY]);

  useEffect(() => {
    

    animationRefArrow.current = anime({
      targets: ".arrowDown",
      opacity: [1, 0],
      easing: 'linear',
      autoplay: false,
    });
  }, []);

  useEffect(() => {
    if (!animationRef) return;
    if (location.pathname != "/projects") {
      if (size.width <= props.mobileScreenWidth) {
        if (scrollY < size.height * 0.37) {
          animationRef.current.seek(animationRef.current.duration * (scrollY * 0.005));
          animationRefArrow.current.seek(animationRefArrow.current.duration * (scrollY * 0.01));
        } else {
          animationRef.current.seek(animationRef.current.duration * 1);
          animationRefArrow.current.seek(animationRefArrow.current.duration * 1);
        }
      } else {
        animationRef.current.seek(animationRef.current.duration * (scrollY * 0.005));
        animationRefArrow.current.seek(animationRefArrow.current.duration * (scrollY * 0.01));
      }
    }
  }, [location.pathname, scrollY]);

  function handleOnClick() {
    if (location.pathname != "/projects") {
      history.push("/");
    } else {
      anime({
        targets: ['.archive .item div', '.logo'],
        opacity: [0],
        easing: 'linear',
        autoplay: true,
        duration: 700,
        complete: function () {
          document.getElementsByClassName('logo')[0].style.transform = 'scale(1)';
          history.push("/");
        },
      });
    }
  }

  return (
    <div className={`logo`}
      style={styleLogo}
    >
      <Link onClick={handleOnClick} >
        <img src={logo}
          style={styleImg}
        />
      </Link>

    </div>
  );
}


export default Logo;
