const allFiles = [
    "8.jpg",                                                                                                                      
    "9.jpg",                                                                                                                      
    "12.jpg",                                                                                                                     
    "13.jpg",                                                                                                                     
    "11.jpg",                                                                                                                                                                                                                                 
    "10.jpg",                                                                                                                     
    "4.jpg",                                                                                                                      
    "5.jpg",                                                                                                                      
    "7.jpg",                                                                                                                      
    "6.jpg",                                                                                                                      
    "2.jpg",                                                                                                                      
    "3.jpg",    
];

function removeMoviesFromList() {
    var items = [];
    allFiles.forEach(f => {
        if (!f.includes(".mp4")) {
            items.push(f);
        }
    });
    return items;
}

export const files = removeMoviesFromList();

export const randomImg = files[Math.floor(Math.random() * files.length)];