import React, { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import anime from 'animejs';
import useScrollPosition from '@react-hook/window-scroll';
import useWindowSize from './helpers/useWindowSize';

import './App.scss';
import Archive from './Archive';
import Home from './Home';
import Logo from './Logo';
import brg from './img/brg-image.jpg';
import { randomImg } from './helpers/archiveFiles.js';

function App() {
  const history = useHistory();
  const location = useLocation();
  const scrollY = useScrollPosition(60 /*fps*/);
  const size = useWindowSize();
  const mobileScreenWidth = 507;
  var scaleRatio = size.width > mobileScreenWidth ? 0.83 : 1;
  var topMargin = size.width > mobileScreenWidth ? 0 : 0; //10 : -10
  var logoMiddle = size.height * 0.37;

  useEffect(() => {
    let overlayEl = document.getElementsByClassName('overlay')[0];
    if (size.width < mobileScreenWidth && scrollY > 2) {
      overlayEl.classList.add('lessBlurMobile');
      overlayEl.classList.remove('moreBlurMobile');
    } else {
      overlayEl.classList.remove('lessBlurMobile');
      overlayEl.classList.add('moreBlurMobile');
    }
  }, [size.width, scrollY]);

  const duration = 700;
  const easing = 'linear';

  function transitionHomeToArchive(path) {
    anime({
      targets: ['.home'],
      opacity: [1, 0],
      easing: easing,
      autoplay: true,
      duration: duration,
      complete: function () {
        history.push("/" + path);
      },
    });
  }

  function transitionArchiveToHome(path) {
    anime({
      targets: ['.archive .item div', '.logo'],
      opacity: [0],
      easing: easing,
      autoplay: true,
      duration: duration,
      complete: function () {
        document.getElementsByClassName('logo')[0].style.transform = 'scale(1)';
        history.push("/" + path);
      },
    });
  }

  function transitionHomeMoveLogo(path, startPos) {
    anime({
      targets: ".logo",
      translateY: [startPos, topMargin],
      scale: scaleRatio,
      easing: 'linear',
      duration: 500,
      complete: function () {
        // homeAnime.play();
        transitionHomeToArchive(path);
      }
    });
  }

  function handleOnClick(path) {
    if (path == "projects" && location.pathname != "/projects") {
      if (scrollY == 0) {
        transitionHomeMoveLogo(path, logoMiddle);
      } else {
        if (getComputedTranslateY(document.getElementsByClassName("logo")[0]) > 10) {
          transitionHomeMoveLogo(path, getComputedTranslateY(document.getElementsByClassName("logo")[0]));
        } else {
          transitionHomeToArchive(path);
        }
      }
    } else if ((path == "info" && location.pathname != "/info" && location.pathname != "/")) {
      // archiveAnime.play();
      transitionArchiveToHome(path);
    } else if (location.pathname == "/" || location.pathname == "/info") {
      if (document.body.scrollHeight - scrollY > 0) {
        scrollToBottom();
      }
    }
  };

  function getComputedTranslateY(obj) {
    if (!window.getComputedStyle) return;
    var style = getComputedStyle(obj),
      transform = style.transform || style.webkitTransform || style.mozTransform;
    var mat = transform.match(/^matrix3d\((.+)\)$/);
    if (mat) return parseFloat(mat[1].split(', ')[13]);
    mat = transform.match(/^matrix\((.+)\)$/);
    return mat ? parseFloat(mat[1].split(', ')[5]) : 0;
  }

  function scrollToBottom() {
    anime({
      targets: [document.documentElement, document.body],
      scrollTop: document.body.scrollHeight,
      duration: 1000,
      easing: 'easeInOutQuad',
      delay: 0,
    });
  }

  return (
    <div>
      <div id="brg" style={{ backgroundImage: `url(archiveimages/${randomImg})` }}>
        <div className="overlay"></div>
        <div className="blur"></div>
      </div>
      <div className="app" >
        <div className="gradientTop" 
          // style={{ display: size.width <= mobileScreenWidth ? "block" : "none" }}
        ></div>
        <div className="gradient"></div>
        <header className="header">
          {/* <Link className="linkArchive" onClick={() => handleOnClick('projects')}>Projects</Link> */}
          <Link className="linkInfo" onClick={() => handleOnClick('info')}>Info</Link>
        </header>
        <div className="logoContainer">
          <Logo scaleRatio={scaleRatio} topMargin={topMargin} logoMiddle={logoMiddle} mobileScreenWidth={mobileScreenWidth} />
        </div>
        <main className="content">
          <Switch>
            {/* <Route path="/projects">
              <Archive 
                handleOnClick={null} 
                mobileScreenWidth={mobileScreenWidth} 
              />
            </Route> */}
            <Route path="/">
              <Home 
                size={size} 
                scrollToBottom={scrollToBottom} 
                mobileScreenWidth={mobileScreenWidth} 
                handleOnClick={size.width > mobileScreenWidth ? handleOnClick : null}
              />
            </Route>
          </Switch>
        </main>
      </div>
    </div>
  );
}

export default App;
