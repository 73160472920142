import React, { useState, useEffect, useRef } from 'react';
import anime from 'animejs';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function GridItem(props) {
    const id = `image-${props.index}`;

    function showImages(hovering) {
        if (hovering) {
            anime({
                targets: ['.archive.noImages .item div', '.archive.noImages .item figure'],
                opacity: 1,
                easing: 'linear',
                autoplay: true,
                duration: 2000,
                delay: 1300,
            });
        } else {
            anime({
                targets: ['.archive.noImages .item div', '.archive.noImages .item figure'],
                opacity: [0],
                easing: 'linear',
                autoplay: true,
                duration: 2000,
            });
        }
    }
    function transitionToArchive() {
        props.handleOnClick("projects");
    }

    return (
        <div className={"item " + props.extraClass}>
            <span
                className="itemIndex"
                onClick={props.handleOnClick != null ? () => transitionToArchive() : null}
                onMouseEnter={props.handleOnClick != null ? () => props.showImages(true) : null}
                onMouseLeave={props.handleOnClick != null ? () => props.showImages(false) : null}
            >
                {props.index ? "( " + props.index + " )" : ""}
            </span>

            <Zoom transitionDuration={500} zoomMargin={40} style={{ opacity: 0 }}>
                {props.link.includes(".mp4") ?
                    <figure id={id}>
                        <video style={{}} controls>
                            <source src={props.link} type="video/mp4"></source>
                        </video>
                    </figure>
                    :
                    <figure id={id} style={{ backgroundImage: `url(${props.link})` }} ></figure>
                }
            </Zoom>
        </div>
    );
}

export default GridItem;